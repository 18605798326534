<template>
  <div v-if="mobile" class="w-100 relative is-mobile pa-2" :class="{ 'is-dense': isDenseMobile }">
    <div class="p-index">
      <div class="p-index__hero flex-wrap justify-center">
        <div class="p-index__hero-content d-flex flex-column align-center">
          <div class="d-flex justify-center w-100">
            <v-img :src="yukariUrl" :height="Math.min((200 * height) / 760 - 20, 200)" />
          </div>
          <div class="d-flex justify-center">
            <h1 class="p-index__title mb-4">さあ、<br />新しい声を手に入れよう。</h1>
          </div>
          <div class="p-index__subttile mb-2 d-flex justify-center text-center">
            <div>
              <a v-scroll-to="{ el: '#aivoice', offset: -80 }" href="#">A.I.VOICE<sup>※</sup></a
              >によるもう一つの「声」<br />
              コミュニティーを盛り上げて<br />
              ボイスチャットを楽しくする<br />
              今までにない読み上げBOT<br />
            </div>
          </div>
          <div id="aivoice" class="text-caption text-grey-darken-1 mb-4 text-center">
            ※A.I.VOICEは株式会社エーアイの登録商標です。<br />
            <a
              class="p-index__text-underline text-grey-darken-1"
              href="https://aivoice.jp/about/"
              target="_blank"
              rel="noreferrer noopener"
            >
              A.I.VOICEとは（外部サイト）<v-icon>mdi-open-in-new</v-icon>
            </a>
          </div>
          <div class="d-flex flex-wrap p-index__gap justify-center">
            <v-btn
              class="p-index__btn"
              :class="{ 'mr-2': !mobile }"
              color="discord-primary"
              target="_blank"
              rel="noreferrer noopener"
              :href="inviteUrl"
            >
              <v-img src="/icon_clyde_white_RGB.svg" width="24" class="mr-2" />
              VOISCORDをサーバーに招待
            </v-btn>
            <v-btn v-if="user" class="p-index__btn" color="grey-darken-2" to="/dashboard"> ダッシュボード </v-btn>
            <v-btn v-else v-scroll-to="{ el: '#features' }" class="p-index__btn" color="grey-darken-2" href="#">
              機能を見る
            </v-btn>
          </div>
        </div>
        <v-img v-if="!mobile" :src="yukariUrl" height="200" />
      </div>
      <div id="features" class="d-flex justify-center">
        <div class="p-index__section-title">機能</div>
      </div>
      <div class="p-index__feature flex-wrap">
        <div class="p-index__feature-description">
          <div class="p-index__feature-title">Webダッシュボード</div>
          <div>
            全ての設定をWebダッシュボードから変更可能。<br />
            <br />
            VCごとに読み上げ対象チャンネルを紐づけるなど<br />
            細かい設定に対応<br />
          </div>
        </div>
        <v-img :src="featureCustomizeUrl" height="360" width="360" />
      </div>
      <div class="p-index__feature flex-wrap">
        <div class="p-index__feature-description">
          <div class="p-index__feature-title">自分好みにカスタマイズ</div>
          <div>
            多彩な設定項目を用意しました。
            <br />
            <div class="d-inline-block text-left">
              ・自動参加機能<br />
              ・サーバー辞書<br />
              ・メンバー別の読み上げ設定<br />
              ・etc...<br />
            </div>
            <br />
            もちろん、 <b>設定なしでもすぐに</b> ご利用いただけます
          </div>
        </div>
        <v-img :src="featurePreferencesUrl" height="360" width="360" />
      </div>
      <div id="plan" class="d-flex justify-center mt-12">
        <div class="p-index__section-title">ブースト特典</div>
      </div>
    </div>
    <VcPlans :show-title="false" style="width: 1000px" />
    <div class="p-index__statistics w-100 py-2">
      <v-carousel
        v-model="carouselItem"
        height="auto"
        hide-delimiters
        :cycle="carouselCycle"
        interval="3000"
        :touch="CarouselTouchHandler"
      >
        <template #prev="{ props }">
          <v-btn
            v-if="!isDenseMobile"
            :class="props.class"
            :icon="props.icon"
            :aria-label="props['aria-label']"
            @click="
              () => {
                props.onClick();
                carouselCycle = false;
              }
            "
          />
        </template>
        <template #next="{ props }">
          <v-btn
            v-if="!isDenseMobile"
            :class="props.class"
            :icon="props.icon"
            :aria-label="props['aria-label']"
            @click="
              () => {
                props.onClick();
                carouselCycle = false;
              }
            "
          />
        </template>
        <v-carousel-item v-for="stat in statistics" :key="stat.title">
          <div class="d-flex justify-center align-center">
            <StatisticsCard v-bind="stat" :class="{ 'p-index__statistics-card': !isDenseMobile }" />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
  <div v-else class="w-100 relative">
    <div class="p-index__statistics p-index__statistics-loop-wrapper w-100 py-2 d-flex">
      <div v-for="i in [1, 2, 3]" :key="i" class="p-index__statistics-loop d-flex">
        <StatisticsCard
          v-for="stat in statistics"
          :key="stat.title"
          v-bind="stat"
          class="p-index__statistics-card mx-2"
        />
      </div>
    </div>
    <div class="p-index">
      <div class="p-index__hero">
        <div class="flex-grow-1">
          <div>
            <h1 class="p-index__title mb-4">さあ、<br />新しい声を手に入れよう。</h1>
          </div>
          <div class="p-index__subttile mb-2">
            <div>
              <a v-scroll-to="{ el: '#aivoice', offset: -80 }" href="#">A.I.VOICE<sup>※</sup></a
              >によるもう一つの「声」<br />
              コミュニティーを盛り上げて<br />
              ボイスチャットを楽しくする<br />
              今までにない読み上げBOT<br />
            </div>
          </div>
          <div id="aivoice" class="text-caption text-grey-darken-1 mb-4">
            ※A.I.VOICEは株式会社エーアイの登録商標です。
            <a
              class="p-index__text-underline text-grey-darken-1"
              href="https://aivoice.jp/about/"
              target="_blank"
              rel="noreferrer noopener"
            >
              A.I.VOICEとは（外部サイト）<v-icon>mdi-open-in-new</v-icon>
            </a>
          </div>
          <div class="d-flex">
            <v-btn
              class="p-index__btn mr-2"
              color="discord-primary"
              target="_blank"
              rel="noreferrer noopener"
              :href="inviteUrl"
            >
              <v-img src="/icon_clyde_white_RGB.svg" width="24" class="mr-2" />
              VOISCORDをサーバーに招待
            </v-btn>
            <v-btn v-if="user" class="p-index__btn" color="grey-darken-2" to="/dashboard"> ダッシュボード </v-btn>
            <v-btn v-else v-scroll-to="{ el: '#features' }" class="p-index__btn" color="grey-darken-2" href="#">
              機能を見る
            </v-btn>
          </div>
        </div>
        <v-img :src="yukariUrl" width="200" />
      </div>
      <div id="features" class="d-flex justify-center">
        <div class="p-index__section-title">機能</div>
      </div>
      <div class="p-index__feature">
        <div class="p-index__feature-description">
          <div class="p-index__feature-title">Webダッシュボード</div>
          <div>
            全ての設定をWebダッシュボードから変更可能。<br />
            <br />
            VCごとに読み上げ対象チャンネルを紐づけるなど<br />
            細かい設定に対応<br />
          </div>
        </div>
        <v-img :src="featureCustomizeUrl" height="360" width="360" />
      </div>
      <div class="p-index__feature">
        <div class="p-index__feature-description">
          <div class="p-index__feature-title">自分好みにカスタマイズ</div>
          <div>
            多彩な設定項目を用意しました。
            <br />
            <div class="d-inline-block text-left">
              ・自動参加機能<br />
              ・サーバー辞書<br />
              ・メンバー別の読み上げ設定<br />
              ・etc...<br />
            </div>
            <br />
            もちろん、 <b>設定なしでもすぐに</b> ご利用いただけます
          </div>
        </div>
        <v-img :src="featurePreferencesUrl" height="360" width="360" />
      </div>
      <div id="plan" class="d-flex justify-center mt-12">
        <div class="p-index__section-title">ブースト特典</div>
      </div>
    </div>
    <VcPlans :show-title="false" style="width: 1000px" />
  </div>
</template>

<script lang="ts" setup>
import type { ElasticStatistics } from "types";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import yukariUrl from "@/assets/images/yukari.png";
import featurePreferencesUrl from "@/assets/images/feature_preferences.svg";
import featureCustomizeUrl from "@/assets/images/feature_customize.svg";
import { useAPI } from "@/composable/useAPI";
import { useRuntimeConfig } from "@/composable/useRuntimeConfig";
import { useUserStore } from "@/stores/useUserStore";

const { discordOauthClientId, discordBotPermissions } = useRuntimeConfig();

const { mobile, height } = useDisplay();
const isDenseMobile = computed(() => mobile.value && height.value < 760);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const elasticStatistics = ref<ElasticStatistics>();
const carouselItem = ref(0);
const carouselCycle = ref(true);

const inviteUrl = computed(() => {
  const params = new URLSearchParams([
    ["client_id", discordOauthClientId],
    ["permissions", discordBotPermissions],
    ["scope", "bot"],
  ]);
  return `https://discord.com/oauth2/authorize?${params}`;
});

const statistics = computed(() => {
  if (!elasticStatistics.value) return [];

  return [
    {
      title: "現在の同時接続数",
      num: elasticStatistics.value.unique_voice,
      unit: "チャンネル",
      color: "green-accent-3",
      icon: "mdi-access-point",
    },
    { title: "累計導入数", num: elasticStatistics.value.max_guilds, unit: "サーバー", color: "white", icon: "" },
    {
      title: "先月の利用サーバー数",
      num: elasticStatistics.value.unique_guilds,
      unit: "サーバー",
      color: "white",
      icon: "",
    },
    {
      title: "先月のユニークユーザー数",
      num: elasticStatistics.value.unique_users,
      unit: "ユーザー",
      color: "white",
      icon: "",
    },
    { title: "先月の読み上げ数", num: elasticStatistics.value.sum_char, unit: "文字", color: "white", icon: "" },
    {
      title: "先月の最大同時接続数",
      num: elasticStatistics.value.max_connections,
      unit: "チャンネル",
      color: "white",
      icon: "",
    },
  ];
});

const CarouselTouchHandler = {
  left: () => {
    carouselItem.value = (carouselItem.value + 1) % 5;
    carouselCycle.value = false;
  },
  right: () => {
    carouselItem.value = (carouselItem.value + 4) % 5;
    carouselCycle.value = false;
  },
};

onMounted(() => {
  useAPI()
    .post("api/statistics/lp_statistics")
    .then(({ data }) => {
      elasticStatistics.value = data;
    });
});
</script>

<style lang="scss" scoped>
@mixin mobile {
  .is-mobile &,
  &.is-mobile {
    @content;
  }
}

.p-index {
  min-width: 780px;
  width: 780px;
  margin: 0 auto;

  @include mobile {
    min-width: 100%;
    width: 100%;
  }
}

.p-index__hero {
  display: flex;
  height: calc(100dvh - 64px - 80px);
  align-items: center;

  @include mobile {
    position: relative;
    display: flex;
    height: calc(100dvh - 64px - 8px * 2);
    width: 100%;
    align-items: flex-end;
    padding-bottom: 80px;
  }

  .is-mobile.is-dense & {
    padding-bottom: 0;
  }
}

.p-index__title {
  font-size: 40px;

  @include mobile {
    font-size: min(7.6dvw, 30px);
  }
}

.p-index__subttile {
  font-size: 20px;

  @include mobile {
    font-size: min(3.8dvw, 16px);
  }
}

.p-index__text-underline {
  text-decoration: underline;
}

.p-index__btn {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #fff;
  padding: 0 16px;
  outline: none;
  unicode-bidi: plaintext;
  text-decoration: none;
  gap: 8px;

  &.v-btn.v-btn--density-default {
    height: 48px;
  }

  @media screen and (max-width: 460px) {
    width: 100%;
    gap: 0;
  }
}

.p-index__gap {
  gap: 8px;
}

.p-index__section-title {
  display: inline-block;
  font-size: 36px;
  padding: 0 8px;
  margin-bottom: 64px;
  border-bottom: 2px solid #fff;

  @include mobile {
    margin-top: 32px;
  }
}

.p-index__feature {
  font-size: 16px;
  height: 400px;
  display: flex;
  align-items: center;
  line-height: 200%;

  @include mobile {
    text-align: center;
    height: auto;
    margin-bottom: 64px;
  }
}

.p-index__feature-description {
  flex-grow: 1;
  padding: 8px 16px;
}

.p-index__feature-title {
  font-size: 30px;
  margin: -8px -16px 16px -16px;
}

.p-index__statistics {
  position: absolute;
  user-select: none;

  @include mobile {
    position: absolute;
    user-select: none;
    top: 0;
    left: 0;
    right: 0;
  }
}

.p-index__statistics-card {
  width: 280px;
  min-width: 280px;
}

@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.p-index__statistics-loop-wrapper {
  overflow: hidden;
}

.p-index__statistics-loop {
  animation: infinity-scroll-left 20s linear infinite;
}

@media (hover: hover) {
  .p-index__statistics-loop-wrapper:hover .p-index__statistics-loop {
    animation-play-state: paused;
  }
}
</style>
