<template>
  <div class="c-statistics-card pa-1" :class="{ 'is-dense': isDenseMobile }">
    <v-card class="d-flex pa-3 justify-center" :class="{ 'flex-column': !isDenseMobile, 'gap-3': isDenseMobile }">
      <div
        class="d-flex align-center justify-center font-weight-bold"
        :class="[
          `text-${color}`,
          { 'text-subtitle-1': mobile && !isDenseMobile, 'w-100': isDenseMobile, 'text-h6': !isDenseMobile },
        ]"
      >
        <v-icon v-if="icon" :icon="icon" />
        {{ props.title }}
      </div>
      <v-divider v-if="!isDenseMobile" class="wave-box" :class="`text-${color}`" thickness="3" opacity="100" />
      <span v-else>|</span>
      <div :class="[`text-${color}`, { 'w-100': isDenseMobile }]">
        <div class="d-flex justify-center">
          <div>
            {{ numberFormatter(props.num) }}
          </div>
          <div style="margin-left: 6px">{{ props.unit }}</div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useDisplay } from "vuetify";

const { mobile, height } = useDisplay();

const props = withDefaults(
  defineProps<{
    title: string;
    num: number;
    unit: string;
    color: string;
    icon: string;
  }>(),
  {},
);

const isDenseMobile = computed(() => mobile.value && height.value < 760);

const numberFormatter = (n: number): string => {
  return n.toLocaleString();
};
</script>

<style lang="scss">
.c-statistics-card {
  width: 280px;
  min-width: 280px;

  &.is-dense {
    width: 100%;
    min-width: auto;
    font-size: 0.9rem;
  }
}
</style>
